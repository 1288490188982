import React, { useEffect } from 'react'

const depths = {
    'sun': 5000,
    'sun-contour': 5000,
    'hill-contour': 1000,
    'hill-contour-mask': 1000,
    'hill-fill': 1000,
    'hill-details': 800,
    'graminha01': 150,
    'graminha02': 300,
    'graminha03': 350,
    'graminha04': 400,
    'graminha05': 400,
}

function ArtSVG(props) {
    // ComponentDidMount
    useEffect(() => {
        Object.keys(depths).forEach(key => {
            let el = document.querySelectorAll('#' + key)[0];
            if (el) {
                el.classList.add('parallax-layer');
                el.setAttribute('parallaxdeep', depths[key]);
            }
        });
    }, [])

    return (
        <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 1317 650"
      className="parallax"
    >
      <g clipPath="url(#clip0)">
        <path
          id="sun"
          fill="#FF8062"
          d="M878.324 210.272c10.564-25.22 31.859-46.084 55.314-63.396 23.43-17.492 48.815-31.586 76.232-36.593 27.44-4.826 56.7-.721 84.57 9.098 28.05 9.793 54.5 25.145 73.1 47.314 18.42 22.195 29.17 51.181 30.72 80.189 1.74 28.983-5.35 57.935-17.28 83.9-12.08 26.171-28.79 49.509-50.32 67.465-21.5 18.136-47.81 30.891-75.09 34.225-27.32 3.154-55.47-3.499-81.152-14.733-25.888-11.387-48.969-27.587-66.127-48.675-17.364-21.242-28.652-47.577-34.302-75.635-5.83-28.032-6.203-57.76 4.335-83.159z"
        ></path>
        
        <g>
          <mask
            id="hill-contour-mask"
            width="946"
            height="346"
            x="312"
            y="310"
            maskUnits="userSpaceOnUse"
          >
            <path
              fill="white"
              d="M1133.97 476.651c-82-87.241-196.764-164.965-336.856-166.594-105.923-1.232-200.52 17.756-278.5 43.805C418.862 387.183 346.3 432.058 312 460.942v194.747h945.66c-22.21-47.864-64.38-115.94-123.69-179.038z"
            ></path>
          </mask>
          
          <g mask="url(#hill-contour-mask)">
            <path
              id="hill-contour"
              stroke="#38A169"
              d="M148.164 556.174c56.749-47.789 218.235-139.349 434.308-149.936M370.983 680.742c41.685-35.104 139.882-93.824 274.716-126.721m670.951 321.468c-46.74-100.698-181.79-290.859-378.963-335.912m0 0c-26.126-5.97-53.343-9.392-81.59-9.721-76.75-.892-147.554 8.831-210.398 24.165m291.988-14.444c-65.85-61.232-148.696-112.915-245.634-129.075m0 0c31.954-18.113 122.657-49.427 229.837-29.777 107.18 19.651 186.19 71.918 212.29 95.595m-442.127-65.818a384.708 384.708 0 00-38.599-4.449m-134.632-52.522c32.011 4.311 103.754 20.851 134.632 52.522m0 0a384.682 384.682 0 00-20.176-.765c-17.243-.2-34.186.135-50.806.95m-111.484-35.101c25.522 3.438 76.296 14.647 111.484 35.101m2.558-71.884c32.011 4.311 105.093 30.838 135.972 62.509m76.32-18.489c133.633 34.266 257.488 142.485 348.268 272.325M834.166 374.486c133.633 34.265 278.544 146.373 369.314 276.213M264.837 529.856c31.954-18.113 122.657-49.427 229.837-29.777 60.979 11.18 112.838 32.918 151.025 53.942m233.206-178.665c133.635 34.265 285.995 145.503 376.775 275.343m-702.231-307.92c32.011 4.311 115.875 31.788 146.754 63.459m-88.856-77.929c32.011 4.311 110.357 27.417 141.236 59.088m36.844 144.336c-65.851-61.231-185.533-102.218-282.472-118.377m228.083 123.849c-65.85-61.231-179.392-100.118-276.331-116.278"
            ></path>
          </g>
        </g>
        <g>
          <mask
            id="mask1"
            width="880"
            height="655"
            x="378"
            y="0"
            maskUnits="userSpaceOnUse"
          >
            <path
              fill="#fff"
              fillRule="evenodd"
              d="M1258 0H378v416.192c36.943-21.163 84.472-43.576 140.614-62.33 77.98-26.049 172.577-45.037 278.5-43.805 140.092 1.629 254.856 79.353 336.856 166.594C1193 539.447 1235.05 607.173 1257.34 655h.66V0z"
              clipRule="evenodd"
            ></path>
          </mask>
          <g mask="url(#mask1)">
            <circle
              id="sun-contour"
              cx="1060.49"
              cy="310.544"
              r="168.685"
              stroke="#2F855A"
              strokeWidth="3"
            ></circle>
          </g>
        </g>
        
        <path
          stroke="#2F855A"
          strokeWidth="3"
          d="M1257 649c-232-451.5-705-459-1171 0"
        ></path>

        <path
          id="graminha01"
          stroke="#fff"
          strokeLinecap="round"
          strokeWidth="8"
          d="M-63 480a652.704 652.704 0 014.845-20.691m546.939-425.99C384.053 6.058 295.739 19.43 222.405 56.482m0 0C229.324 44.163 245.93 17.8 257 10.9c11.07-6.899-18.45 27.513-34.595 45.582zm0 0C282.369 48.188 424.438 41.256 513 79.888M222.405 56.483c-29.49 14.9-56.558 33.629-81.297 55.085m0 0c-2.306-11.71-.346-41.681 25.946-67.897m-25.946 67.897a461.103 461.103 0 00-16.347 14.886m0 0c55.611-9.198 195.893-15.177 312.131 34.492m-312.131-34.492c-18.471 17.638-35.54 36.783-51.25 56.913m0 0c2.612-24.72 12.178-80.024 29.543-103.479M73.51 183.367a601.26 601.26 0 00-13.7 18.264m0 0c50.163-11.837 171.936-22.748 257.731 28.301m-257.73-28.301c-12.27 17.018-23.63 34.597-34.102 52.446m0 0c-1.317-19.183.545-66.045 18.534-100.029M25.71 254.077a726.295 726.295 0 00-9.803 17.246m0 0c51.536-6.898 166.716-9.313 215.148 36.218M15.906 271.323c-10.715 19.462-20.401 39.131-29.09 58.638m0 0c-2.191-24.72-4.151-80.713 5.535-106.928m-5.536 106.928a817.491 817.491 0 00-8.878 20.696m0 0c20.372-16.672 90.177-36.563 206.414 17.246m-206.414-17.246a822.68 822.68 0 00-18.184 48.29m-7.81 24.145c.208-20.121 1.662-66.916 5.814-93.131m-5.814 93.131a797.519 797.519 0 017.81-24.145m-7.81 24.145a740.327 740.327 0 00-10.098 36.217m17.908-60.362c28.071-3.45 90.122-6.899 113.757 6.898m-131.665 53.464c7.746-10.348 33.403-27.939 74.061-15.522"
        ></path>

        <path
          id="graminha02"
          stroke="#fff"
          strokeLinecap="round"
          strokeWidth="6"
          d="M230.495 808.296c-17.663-61.262-23.757-131.15-17.53-200.066m343.34-347.246c-77.677-8.12-141.556 9.904-192.343 45.047m0 0c.534-8.112 4.933-26.733 18.253-36.324m-18.253 36.324a270.045 270.045 0 00-16.588 12.451m0 0c50.388-14.551 166.045-39.798 225.567-24.373m-225.567 24.373c-11.548 9.379-22.338 19.695-32.381 30.821m0 0c-3.563-8.415-6.965-30.134 7.939-49.693m-7.939 49.693a314.333 314.333 0 00-10.747 12.563m-33.297 49.269c-.404-18.131 2.036-59.219 15.026-78.521m-15.026 78.521c9.855-17.53 20.947-34.046 33.297-49.269m-33.297 49.269a393.392 393.392 0 00-6.56 12.169m39.857-61.438c33.581-13.448 123.889-32.963 216.464-3.439M245.217 466.116c-2.485-19.128-5.232-62.588 3.66-83.408m-3.66 83.408c5.676-14.744 12.063-29.056 19.172-42.812m-19.172 42.812a460.5 460.5 0 00-3.353 8.985m-15.298 50.827c-4.226-19.756-11.441-64.554-6.488-85.704m6.488 85.704c4.24-17.327 9.335-34.326 15.298-50.827m-15.298 50.827c-.41 1.677-.813 3.356-1.207 5.039m-11.926 72.323c-3.571-21.876-10.095-70.1-7.626-87.99m7.626 87.99a584.922 584.922 0 00-.468 4.94m.468-4.94l.127-1.273m-.127 1.273c5.854-4.85 23.749-14.161 48.498-12.604m2.458-167.382c26.798-14.163 100.3-35.65 179.926-8.29m-202.451 60.087c24.867-11.059 90.172-26 152.451 2.712m-168.956 53.154c8.169-13.696 47.763-34.698 140.78-9.135m-140.78 9.135a546.14 546.14 0 00-7.21 36.329m0 0c13.014-4.591 45.005-12.867 68.862-9.24m-68.862 9.24a570.888 570.888 0 00-4.589 34.721m-.595 6.213l.595-6.213"
        ></path>

        <path
          id="graminha03"
          stroke="#fff"
          strokeLinecap="round"
          strokeWidth="6"
          d="M661.903 733.449c-22.783-24.59-41.94-53.546-56.164-84.906m113.622-324.26c-30.151 6.017-62.935 11.842-95.559 56.702m0 0c-.655-10.072-.869-31.827 3.52-38.274m-3.52 38.274a239.086 239.086 0 00-12.306 18.693m0 0c39.984-33.685 73.978-51.693 138.61-60.921m-138.61 60.921a234.915 234.915 0 00-14.265 28.222m0 0c-6.964-7.503-18.481-25.782-8.829-38.876m8.829 38.876a235.964 235.964 0 00-5.733 14.929m0 0c19.686-20.747 61.412-48.299 133.05-65.088m-133.05 65.088c-5.355 15.494-9.033 31.342-11.169 47.343m0 0c-7.263-14.8-20.299-48.145-14.336-63.127m14.336 63.127a256.97 256.97 0 00-1.13 9.958m0 0c24.437-24.097 68.349-59.106 121.574-69.29m-121.574 69.29c-1.206 12.892-1.437 25.855-.762 38.783m0 0c-7.238-10.09-22.533-36.445-25.8-61.14m25.8 61.14c.156 2.985.361 5.967.612 8.946m4.292 30.822c-10.814-10.414-32.768-35.256-34.073-51.312m34.073 51.312a290.663 290.663 0 01-4.292-30.822m4.292 30.822a302.719 302.719 0 002.816 12.988m-7.108-43.81c20.307-19.753 51.439-49.38 99.96-60.864m-92.852 104.674c.661-13.778 33.19-34.589 90.705-46.959m-90.705 46.959a311.596 311.596 0 008.027 27.702m8.382 21.944c-10.08-10.581-32.057-35.873-39.319-52.393m39.319 52.393a320.348 320.348 0 01-8.382-21.944m8.382 21.944a320.168 320.168 0 003.173 7.228m-11.555-29.172c11.242-9.745 40.273-30.041 66.466-33.264m-54.911 62.436c5.244-7.626 19.614-23.986 35.146-28.422"
        ></path>

        <path
          id="graminha04"
          stroke="#fff"
          strokeLinecap="round"
          strokeWidth="6"
          d="M981.298 692a327.687 327.687 0 003.833-7.809M943.007 414c38.992 15.504 60.543 42.755 70.603 74.966m0 0c1.13-8.069 2.72-25.769 0-32.017m0 32.017c-19.887-17.7-66.852-54.35-95.61-59.348m95.61 59.348c3.2 10.244 5.24 20.989 6.3 32.017m0 0c5.1-6.768 14.53-22.646 11.4-32.017m-11.4 32.017c-20.684-16.138-70.027-47.635-101.91-44.511m101.91 44.511c1.48 15.268 1.09 31.079-.65 46.854m0 0c4.53-7.809 14.24-28.112 16.74-46.854m-16.74 46.854c-14.22-16.138-50.622-47.166-82.505-42.168m82.505 42.168c-1.31 11.815-3.37 23.61-5.99 35.141m0 0c7.58-11.193 22.73-36.234 22.73-46.854m-22.73 46.854c-11.44-11.974-41.506-34.829-70.263-30.456m70.263 30.456c-2.81 12.439-6.27 24.571-10.09 36.091m0 0c-2.87-9.948-20.163-31.094-66.425-36.091m66.425 36.091c7.81-10.469 24.38-34.061 28.13-44.681m-28.13 44.681a413.623 413.623 0 01-9.137 24.819m0 0c-4.248-6.304-17.59-19.502-36.97-21.866m36.97 21.866a391.926 391.926 0 01-4.969 11.713m0 0c6.079-7.435 19.496-25.152 24.536-36.532m-24.536 36.532a366.548 366.548 0 01-3.943 8.59m0 0c-3.101-6.768-11.96-20.303-22.588-20.303"
        ></path>

        <path
          id="graminha05"
          stroke="#fff"
          strokeLinecap="round"
          strokeWidth="6"
          d="M1232 682c3.52-12.541 6.63-27.702 8.45-44M1146 483c29.06 2.517 49.99 13.113 64.75 28.5m0 0c-.75-4.333-2.95-14-5.75-18m5.75 18a89.054 89.054 0 014.85 5.5m9.16 13.5c1.09-4.333 2.61-14.8 0-22m0 22c-2.68-4.754-5.72-9.273-9.16-13.5m9.16 13.5a107.66 107.66 0 013.14 6m7.42 19.5c1.03-7.167 1.81-23.8-3.32-33m3.32 33a143.543 143.543 0 012.09 8m-2.09-8c-1.97-6.762-4.42-13.299-7.42-19.5m12.21 42c1.8-6.5 4.69-21.8 1.89-31m-1.89 31c-.7-4.895-1.59-9.741-2.7-14.5m2.7 14.5c.28 1.987.53 3.982.75 5.983m1.14 16.081c2.17-5.188 6.6-18.264 7-29.064m-7 29.064c-.18-5.372-.55-10.747-1.14-16.081m1.14 16.081c.08 2.314.12 4.627.13 6.936m-1.27 26.5c2.71-7.833 8.14-24.9 8.14-30.5m-8.14 30.5c.34-3.46.61-6.964.82-10.5m-.82 10.5c-.13 1.34-.27 2.674-.41 4m-24.85-121c-13.2-7.667-47.2-22.6-77.6-21m89.9 40.5c-8.3-7-35.4-19.5-77.4-13.5m86.91 41c-5.97-7.5-27.11-20.5-63.91-12.5m67.36 32.983c-5.95-5.328-25.36-13.983-55.36-5.983m56.63 29c-1.71-5.333-14.23-14.8-50.63-10m50.63 10c.02 5.372-.14 10.721-.45 16m0 0c-2.16-2.5-10.39-7.4-26.08-7m24.85 21.5c-.56-2.167-4.48-6.8-15.69-8"
        ></path>
      </g>

      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0H1317V650H0z"></path>
        </clipPath>
      </defs>
    </svg>
    );
}

export default ArtSVG;
