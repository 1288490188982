import React from "react";

import './IconRotate.css'


function IconRotateDevice(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="70"
            height="70"
            fill="none"
            viewBox="0 0 70 70"
        >
            <path
                fill="currentColor"
                d="M5.85 48.142V21.859a4.375 4.375 0 014.375-4.375h49.583a4.375 4.375 0 014.375 4.375v26.283a4.375 4.375 0 01-4.375 4.375H10.225a4.375 4.375 0 01-4.375-4.375zm2.917 0c0 .805.652 1.458 1.458 1.458h49.583c.806 0 1.459-.653 1.459-1.458V21.859c0-.806-.653-1.459-1.459-1.459H10.225c-.806 0-1.458.653-1.458 1.459v26.283zm49.583-8.75a1.458 1.458 0 11-2.917 0v-8.75a1.458 1.458 0 012.917 0v8.75z"
                className="phone"
            ></path>
            <mask
                id="mask0"
                width="60"
                height="36"
                x="5"
                y="17"
                maskUnits="userSpaceOnUse"
            >
                <path
                    fill="white"
                    d="M5.85 48.142V21.859a4.375 4.375 0 014.375-4.375h49.583a4.375 4.375 0 014.375 4.375v26.283a4.375 4.375 0 01-4.375 4.375H10.225a4.375 4.375 0 01-4.375-4.375zm52.5-8.75a1.458 1.458 0 11-2.917 0v-8.75a1.458 1.458 0 012.917 0v8.75z"
                    className="phone"
                ></path>
            </mask>
            <g mask="url(#mask0)">
                <path
                    stroke="currentColor"
                    strokeWidth="2"
                    d="M28.251 31.5c.866-4.558 4.817-8 9.559-8 5.376 0 9.735 4.424 9.735 9.882a9.915 9.915 0 01-4.045 8.019m6.829 10.654c-1.27-2.78-3.683-6.732-7.075-10.396-4.69-5.066-11.256-9.58-19.27-9.674a47.926 47.926 0 00-15.93 2.544c-5.706 1.935-9.856 4.54-11.818 6.217"
                ></path>
            </g>
        </svg>

    );
}

export default IconRotateDevice;
